import type { RouteLocationNamedRaw } from 'vue-router'

import { Country, MarketCountryCode } from '@backmarket/http-api'

import { CMS } from '../../routes-names'

export const PRESS_LINKS_BY_COUNTRY: Partial<
  Record<MarketCountryCode, RouteLocationNamedRaw>
> = {
  // The press page exists and is linked from the carousel only in Japan
  [Country.JP]: {
    name: CMS.EVENT,
    params: { pageName: 'press', locale: 'ja-jp' },
  },
}
